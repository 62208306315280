import React from "react";

import Navbar from "../../organisms/Layout/Navbar";
import Hero from "./Hero";
import Seller from "./Seller";
import Holdingback from "./Holdingback";
import Faq from "../../molecules/Faq";
import Streamlining from "./Streamlining";
import Localise from "./Localise";
import Footer from "../../organisms/Layout/Footer";
import Insights from "./insights";

export const FaqData = [
  {
    heading: "What is multi-currency payment on AWS?",
    content:
      "Multi-currency payment on AWS refers to the capability to conduct transactions and make payments using multiple currencies within the Amazon Web Services (AWS) ecosystem. It allows users to handle financial transactions involving various currencies seamlessly, simplifying cross-border payments and providing flexibility in conducting international business.",
  },
  {
    heading: "How do multi-currency transactions work?",
    content:
      "Multi-currency transactions on AWS typically involve the following steps: Currency Selection, Currency Conversion, Billing and Invoicing, Payment Processing, Account Management. Overall, multi-currency transactions on AWS streamline the payment process for users operating in different regions, enabling them to leverage AWS services efficiently without concerns about currency compatibility.",
  },
  {
    heading: "What currencies does multi-currency work with?",
    content:
      "Multi-currency on AWS supports a range of currencies to accommodate users worldwide. Specific supported currencies can be checked with AWS documentation or account settings.",
  },
  {
    heading: "Does multi-currency only work with INR to USD and vice versa?",
    content:
      "No, multi-currency on AWS is not limited to specific currency pairs like INR to USD. It supports a variety of currencies, enabling users to conduct transactions in their preferred currency.",
  },
  {
    heading: "Is there a specific transaction limit to use multi-currency?",
    content:
      "There may be specific transaction limits associated with multi-currency usage on AWS, depending on the user's account type, region, and other factors. Users should refer to AWS documentation or account settings for information on transaction limits.",
  },
];

export default function index() {
  return (
    <div>
      <div className=" bg-primary-vdark  ">
        <Navbar dark />
        <Hero />
      </div>
      <Seller />
      <Holdingback />
      <Streamlining />
      <Localise />
      <Insights />
      <Faq data={FaqData} />
      <Footer />
    </div>
  );
}
