import React from "react";
import Multi from "../components/Multi-Currency/index";
import { PageSEO } from "../atoms/SEO";

export default function Buying({ location }) {
  return (
    <div>
      <PageSEO
        path={location.pathname}
        title={"SaaSPay's B2B Buy-Now-Pay-Later to solve for USD payments"}
        description={
          "Eliminate withholding taxes in B2B SaaS and Cloud cross-border transactions. Enjoy the flexibility of INR payments with SaaSPay's Buy-Now-Pay-Later solution."
        }
      />
      <Multi />
    </div>
  );
}
