import React from "react";

import Currencyhero from "../../assets/images/Currencyhero.svg";

export default function Hero() {
  return (
    <div>
      <div className=" w-11/12 pt-12 md:pt-32 2xl:max-w-[1240px] mx-auto ">
        <div className=" grid grid-cols-12 gap-4  ">
          <div className="  col-span-12 lg:col-span-7 ">
            <div className=" pt-[120px] 2xl:pt-[130px] ">
              <h4 className=" text-5xl sm:text-8xl 2xl:text-[64px] f-f-b-g text-white  xl:leading-[72px] ">
                Bye USD Payments! <br />
                👋 Hello, Smooth
              </h4>
              <h4 className=" text-5xl sm:text-8xl 2xl:text-[64px] f-f-b-g text-green  xl:leading-[72px] ">
                INR Transactions!
              </h4>
              <h5 className=" f-f-m text-lg 2xl:text-[20px]  text-grey pt-[12px] ">
                Eliminate withholding taxes and cross border transactions to
                fast track conversions
              </h5>

              <ul className=" mt-[40px]  md:inline-flex items-center ">
                <li>
                  <button
                    className="  bg-primary w-[251px] h-[64px] rounded-[48px] f-f-b text-lg text-white "
                    onclick={() =>
                      window.open(
                        "https://calendly.com/mukund-saas/get-to-know-saaspay?month=2024-04"
                      )
                    }
                  >
                    Book a Discovery call
                  </button>
                </li>
                <li className=" ml-[24px] mt-[24px] md:mt-0 ">
                  <ul
                    className="inline-flex items-center "
                    onClick={() =>
                      window.open("https://form.jotform.com/240982279984475")
                    }
                  >
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                      >
                        <path
                          d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.2031 9.39844L20.6031 14.9984L12.2031 20.5984V9.39844Z"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </li>
                    <li className=" ml-[12px] ">
                      <h2 className=" f-f-b text-lg text-white ">Learn more</h2>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="  col-span-12 lg:col-span-5 ">
            <img
              src={Currencyhero}
              className=" w-full 2xl:w-[511px] h-[583px] sm:mb-[42px] sm:mt-[42px] lg:mt-[80px] "
            />
          </div>
        </div>
      </div>
    </div>
  );
}
