import React from "react";
import Lottie from "lottie-react";

import Animation2 from "./SaaSPay_3rd Lottie.json";

export default function Streamlining() {
  return (
    <div className=" w-11/12 2xl:max-w-[1240px] mx-auto pt-[65px] md:pt-[144px] ">
      <h2 className=" text-black text-2xl md:text-7xl f-f-b-g text-center md:leading-[56px]  ">
        Streamlining Multi-Currency <br /> Payments with SaaSPay <br />{" "}
        <span className=" text-primary-dark ">
          {" "}
          Go Borderless with SaaSPay{" "}
        </span>
      </h2>
      <Lottie
        animationData={Animation2}
        loop={true}
        autoplay={true}
        className=" h-auto w-full lg:w-[983px] lg:h-[663px] mt-[30px] md:mt-[61px] mx-auto "
        alt=""
      />
    </div>
  );
}
